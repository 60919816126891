<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Bancos</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Listado de Bancos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Bancos
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          icon="pi pi-eye"
          label="VER MOVIMIENTOS"
          class="p-button-outlined mr-2 p-button-lg p-button-danger"
          v-tooltip.top="'Ver Movimientos'"
          @click="verMovimientosBanco"
        />
        <Button
          icon="pi pi-refresh"
          class="p-button-outlined mr-2 p-button-lg"
          v-tooltip.top="'Actualizar Listado'"
          @click="cargarBancos"
        />
        <Button
          label="NUEVO BANCO"
          v-if="'Banco Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Registrar Nuevo Banco'"
          @click="nuevoBanco"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtbancos"
        dataKey="id"
        :value="bancos"
        :paginator="true"
        :loading="cargando"
        :rows="5"
        :filters="buscar"
        :rowHover="true"
        class="p-datatable-sm"
        stripedRows
        showGridlines
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 100, 200]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Bancos"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-dblclick="nuevoMovBanco($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Bancos"
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span
            style="
              background-color: #f8d7da;
              padding: 5px;
              border-radius: 5px;
              color: #721c24;
            "
            class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column
          field="saldo_anterior"
          header="SALDO ANTERIOR"
          class="text-right"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.saldo_anterior) }}
          </template>
        </Column>
        <Column field="saldo_actual" header="SALDO ACTUAL" class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.saldo_actual) }}
          </template>
        </Column>
        <Column
          field="titular"
          header="TITULAR"
          style="font-weight: bold"
        ></Column>
        <Column field="descripcion" header="DESCRIPCIÓN"></Column>
        <Column field="tipo_cuenta" header="TIPO CUENTA">
          <template #body="{ data }">
            <span v-if="data.tipo_cuenta == 1">Ahorro</span>
            <span v-else>Corriente</span>
          </template>
        </Column>
        <Column field="numero_cuenta" header="NÚMERO CUENTA"></Column>
        <Column field="moneda" header="MONEDA">
          <template #body="{ data }">
            <span v-if="data.moneda == 1">Bolivianos</span>
            <span v-else>Dólares</span>
          </template>
        </Column>

        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <Badge
              :severity="data.estado == 1 ? 'success' : 'danger'"
              :value="data.estado == 1 ? 'ACTIVO' : 'INACTIVO'"
            />
          </template>
        </Column>

        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <MovBanco
        :show="mov_bancoDialog"
        :mov_banco="banco"
        @closeModal="cerrarModalMovBanco"
        @actualizarListado="cargarBancos"
      />
      <BancoCreate
        :show="nuevoBancoDialog"
        :banco="banco"
        @closeModal="cerrarModalNuevoBanco"
        @actualizarListado="cargarBancos"
      />
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import BancoService from "@/service/BancoService";
import MovBanco from "@/module/bancos/MovBanco.vue";
import { useAuth } from "@/stores";
import BancoCreate from "@/module/bancos/BancoCreate.vue";
export default {
  components: {
    BancoCreate,
    MovBanco,
  },

  data() {
    return {
      cargando: true,
      bancos: [],
      mov_bancoDialog: false,
      nuevoBancoDialog: false,
      banco: {},
      mov_banco: {},
      totalRegistros: 0,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  bancoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.bancoService = new BancoService();
  },
  mounted() {
    this.cargarBancos();
  },
  methods: {
    verMovimientosBanco() {
      this.$router.push("/movbanco");
    },
    acciones(datos) {
      return [
        {
          label: "Nuevo Movimiento",
          icon: "pi pi-money-bill",
          command: () => {
            this.nuevoMovBanco(datos);
          },
        },
        {
          label: "Editar",
          icon: "pi pi-pencil",
          command: () => {
            this.editarBanco(datos);
          },
        },
        {
          label: "Eliminar",
          icon: "pi pi-trash",
          command: () => {
            this.confirmarEliminar(datos.id);
          },
        },
      ];
    },
    nuevoMovBanco(banco) {
      this.banco = { ...banco };
      this.mov_bancoDialog = true;
    },
    cerrarModalMovBanco() {
      this.mov_bancoDialog = false;
      this.banco = {};
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarBancos() {
      this.cargando = true;
      this.bancoService.getBancosAll().then((data) => {
        this.bancos = data.bancos;
        this.totalRegistros = data.bancos.length;
        this.cargando = false;
      });
    },
    nuevoBanco() {
      this.nuevoBancoDialog = true;
      this.banco = {
        estado: { label: "ACTIVO", value: 1 },
        tipo_cuenta: { label: "Ahorro", value: 1 },
        moneda: { label: "Bolivianos", value: 1 },
      };
    },
    cerrarModalNuevoBanco() {
      this.nuevoBancoDialog = false;
      this.banco = {};
    },
    editarBanco(banco) {
      this.banco = {
        ...banco,
        estado: {
          label: banco.estado == 1 ? "ACTIVO" : "INACTIVO",
          value: banco.estado,
        },
        tipo_cuenta: {
          label: banco.tipo_cuenta == 1 ? "Ahorro" : "Corriente",
          value: banco.tipo_cuenta,
        },
        moneda: {
          label: banco.moneda == 1 ? "Bolivianos" : "Dólares",
          value: banco.moneda,
        },
        saldo_actual: parseFloat(banco.saldo_actual),
      };
      this.nuevoBancoDialog = true;
    },
    confirmarEliminar(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea eliminar el Banco?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo eliminar",
        rejectLabel: "No, cancelar",
        accept: () => {
          this.bancoService.eliminarBanco(id).then((response) => {
            if (response.success == false) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.message,
                life: 3000,
              });
              this.cargarBancos();
            }
          });
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>