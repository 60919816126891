<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '650px' }"
    header="Banco Detalle"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span for=""><strong>SALDO ACTUAL: </strong></span>
          <InputNumber
            v-model="data_banco.saldo_actual"
            :min="0"
            locale="de-DE"
            input-class="text-right"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
          <small class="p-invalid" v-if="errors.saldo_actual">{{
            errors.saldo_actual[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-5">
          <span for=""
            ><strong>TITULAR: </strong><span class="p-invalid">*</span></span
          >
          <Textarea v-model="data_banco.titular" autoResize :rows="1" />
          <small class="p-invalid" v-if="errors.titular">{{
            errors.titular[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span for=""
            ><strong>DESCRIPCI&Oacute;N: </strong
            ><span class="p-invalid">*</span></span
          >
          <Textarea v-model="data_banco.descripcion" autoResize :rows="1" />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span for=""
            ><strong>TIPO DE CUENTA: </strong
            ><span class="p-invalid">*</span></span
          >
          <Dropdown
            v-model="data_banco.tipo_cuenta"
            :options="tipos_cuenta"
            optionLabel="label"
            placeholder="Seleccione"
          />
          <small class="p-invalid" v-if="errors.tipo_cuenta">{{
            errors.tipo_cuenta[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span for=""
            ><strong>N&Uacute;MERO DE CUENTA: </strong
            ><span class="p-invalid">*</span></span
          >
          <InputText v-model="data_banco.numero_cuenta" />
          <small class="p-invalid" v-if="errors.numero_cuenta">{{
            errors.numero_cuenta[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span for=""
            ><strong>MONEDA: </strong><span class="p-invalid">*</span></span
          >
          <Dropdown
            v-model="data_banco.moneda"
            :options="monedas"
            optionLabel="label"
            placeholder="Seleccione"
          />
          <small class="p-invalid" v-if="errors.moneda">{{
            errors.moneda[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></span
          >
          <Dropdown
            id="estado"
            v-model="data_banco.estado"
            :disabled="!data_banco.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarBanco"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import BancoService from "@/service/BancoService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    banco: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_banco: this.banco,
      enviado: false,
      errors: {},
      tipos_cuenta: [
        { label: "Ahorro", value: 1 },
        { label: "Corriente", value: 2 },
      ],
      monedas: [
        { label: "Bolivianos", value: 1 },
        { label: "Dólares", value: 2 },
      ],
      estados: [
        { label: "ACTIVO", value: 1 },
        { label: "INACTIVO", value: 0 },
      ],
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  bancoService: null,
  created() {
    this.bancoService = new BancoService();
  },
  methods: {
    validacionesFormulario() {
      this.errors = {};
      if (!this.data_banco.titular) {
        this.errors.titular = ["El campo titular es requerido"];
      }
      if (!this.data_banco.descripcion) {
        this.errors.descripcion = ["El campo descripción es requerido"];
      }
      if (!this.data_banco.tipo_cuenta) {
        this.errors.tipo_cuenta = ["El campo tipo de cuenta es requerido"];
      }
      if (!this.data_banco.numero_cuenta) {
        this.errors.numero_cuenta = ["El campo número de cuenta es requerido"];
      }
      if (!this.data_banco.moneda) {
        this.errors.moneda = ["El campo moneda es requerido"];
      }
    },

    guardarBanco() {
      this.validacionesFormulario();
      if (Object.keys(this.errors).length > 0) {
        // Verifica si hay errores
        return;
      }
      this.errors = {};
      this.enviado = true;
      if (this.banco.id) {
        let banco_enviar = {
          ...this.data_banco,
          estado: this.data_banco.estado.value,
          tipo_cuenta: this.data_banco.tipo_cuenta.value,
          moneda: this.data_banco.moneda.value,
        };
        this.bancoService.editarBanco(banco_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            if (data.success == false) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.message,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarModal();
            }
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_banco;
        this.data_banco = {
          ...this.data_banco,
          estado: this.data_banco.estado.value,
          tipo_cuenta: this.data_banco.tipo_cuenta.value,
          moneda: this.data_banco.moneda.value,
          saldo_actual: this.data_banco.saldo_actual
            ? this.data_banco.saldo_actual
            : 0,
        };
        this.bancoService.nuevoBanco(this.data_banco).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_banco = tmp;
          } else {
            this.errors = {};
            if (data.success == false) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: data.message,
                life: 10000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.message,
                life: 10000,
              });
              this.$emit("actualizarListado", data.banco);
              this.ocultarModal();
            }
          }
          this.enviado = false;
        });
      }
    },
    ocultarModal() {
      this.errors = {};
      this.$emit("closeModal");
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    banco(value) {
      this.data_banco = value;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>